import React, {Component} from "react";
import {mediaUrl} from "../../tools/config";
import YouTube from 'react-youtube';

export default class MediaPlayer extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isPlaying: false,
        };

        this.media = React.createRef();
        this.wrapMediaRef = React.createRef();
    }

    render() {

        let data = this.props.items ? this.props.items : null;
        let { className } = this.props;

        let {
            isPlaying
        } = this.state;

        let videoId = /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi.exec(data.link);

        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 0,
                controls: 0,
                iv_load_policy: 3,
                loop: 1,
                modestbranding: 1,
                showinfo: 0,
                rel: 0,
            }
        };

        return (<div className="media-player-block">
            <div className={"media-player__wrapper " + ( videoId == null ? " media-player__wrapper-img" : "")}>
                {videoId !== null ? <YouTube
                        containerClassName={"media-video-player " + ( !!className ? className : "")}
                        videoId={ videoId[1] }
                        opts={ opts }
                        // onReady={ this._onReady }
                    /> :
                    data && data.imagePath ? <img className="b-product-plate__image b-product-plate__image_def"
                                                  src={ mediaUrl + data.imagePath } alt="" role="presentation" /> : null}
            </div>
        </div>)
    }
}