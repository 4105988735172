import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import {common} from "./common";
import {tariffs} from "./tariffs";
import {regions} from "./regions";
import {infoBlocks} from "./infoBlocks";
import {services} from "./services";
import {internet} from "./internet";
import {faq} from "./faq";
import {completeSets} from "./completeSets";
import {feedback} from "./feedback";
import {roaming} from "./roaming";
import {footer} from "./footerMenu";
import {megaMenu} from "./megaMenu";
import {forms} from "./forms";
import {activation} from "./activation";
import {shops} from "./shops";
import {settings} from "./settings";
import {constructor} from "./constructor";
import {sms} from "./sms";
import {support} from "./support";
import {routes} from "./routes";
import {routesVacancies} from "./routesVacancies";
import {mainMenu} from "./mainMenu";
import {coverImages} from "./coverImages";
import {client} from "./client";
import {vacancies} from "./vacancies";
import {contacts} from "./contacts";
import {cvForm} from "./cvForm";
import {vacanciesFilterSave} from "./vacanciesFilterSave";
import {vacanciesCollapseFlags} from "./vacanciesCollapseFlags";
import {news} from "./news";
import {docs} from "./docs";
import {media} from "./media";
import {chatFormFunc} from "./chat";
import {callback} from "./callback";
import {tariffConstructor} from "./tariffConstructor";
import {sendParamsStore} from "./setSendParamsStore";
import {company} from "./company";
import { searchReducer } from './searchReducer';

import {paymentsTransfers} from "./paymentsTransfers";
import {promotions} from "./promotions";

let rootReducer = combineReducers({
    router: routerReducer,
    loadingBar: loadingBarReducer,
    common,
    tariffs,
    regions,
    infoBlocks,
    services,
    internet,
    faq,
    completeSets,
    feedback,
    roaming,
    footer,
    megaMenu,
    forms,
    activation,
    shops,
    settings,
    constructor,
    sms,
    support,
    routes,
    routesVacancies,
    mainMenu,
    coverImages,
    client,
    vacancies,
    contacts,
    cvForm,
    vacanciesFilterSave,
    vacanciesCollapseFlags,
    news,
    docs,
    media,
    chatFormFunc,
    callbackForm: callback,
    tariffConstructor,
    sendParamsStore,
    company,
    searchReducer,

    paymentsTransfers,
    promotions
});

export default rootReducer;
