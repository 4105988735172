import PropTypes from "prop-types";

import {
    deleteQueryParam,
    getQueryParam,
    hasQueryParam
} from "../tools/tools";
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from 'react-router-dom';
import { goBack, push } from 'react-router-redux';
import { chatForm } from "../actions/chat";
import { contactsAbout, docsAbout, getCompanies } from "../actions/company";
import { completeSetsList } from "../actions/completeSets";
import { getTariffConstructor, settings } from "../actions/constructor";
import { contactsList } from "../actions/contacts";
import { footerMenuList } from "../actions/footerMenu";
import { formsList } from "../actions/forms";
import { infoBlocksList } from "../actions/infoBlocks";
import { internetItemsList } from "../actions/internet";
import { mainMenuList } from "../actions/mainMenu";
import { megaMenuList } from "../actions/megaMenu";
import { newsList, newsListShort } from "../actions/news";
import { faqList } from "../actions/questions";
import { setRegions } from "../actions/regions";
import { getPage, routesList, routesVacanciesList, setRoutes } from "../actions/routes";
import { servicesArchiveList, servicesList, servicesView, termsOfService } from "../actions/services";
import { settingsList } from "../actions/settings";
import { shopsList } from "../actions/shops";
import { complain, fastDecisions, ussd } from "../actions/support";
import {
    tariffArchiveList,
    tariffFilterSettings,
    tariffIndividualList,
    tariffSelectionSettings,
    tariffsList
} from "../actions/tariffs";
import { vacanciesList } from "../actions/vacancies";
import AdvertisingLabelingMob from '../components/AdvertisingLabelingMob/AdvertisingLabelingMob';
import {
    details,
    payments,
    services,
    tariffInfo,
    info,
    banServices,
    remainsHistory,
    exchangeMins,
    formsSettings,
    lkMenu,
    lkSettings, checkLogin, logout, callLogin, getAllMyAppeals,
} from "../actions/client";
import MobileAppAdvert from "../components/Header/MobileAppAdvert";
import CookieAgreement from "../components/common/CookieAgreement";
import Modal from "../components/common/Modal";
import B2bTariffConnectionModal from "../components/modals/B2bTariffConnectionModal";
import AvayaChat from "../containers/Avaya/AvayaChat";
import { caseDynamicRoutesB2b } from "../reducers/routes";
import initRoutes, { lkRoutes, notFound } from "../routes";
import { cookieDomain } from "../tools/config";
import { getHeaderInfo, getPreloadInfo } from '../tools/preload-helper';
import { checkUtmParams, getCookie, setCookie, setUtmCookie } from "../tools/tools";
import { getPathWithoutRegion, getRegionFromLocation } from "../tools/url-helper";
import Footer from './Footer/Footer';
import Header from "./Header/Header";
import LKAuth from "./LK/LKAuth";
import LKPage from './LK/LKPage';
import Page404 from "./Page404";
import TariffConstructorAbout from "./Rates/TariffConstructorAbout";
import {clearSearchHintAction, stateSearchInputAction, toggleSearchInputAction} from "../actions/searchAction";

import {flagIsB2bAction} from "../actions/company";
import SuccessForm from "../components/Forms/SuccessForm";
import LocalTokenBlock from "./LocalTokenBlock";

// import TooltipOverlay from '../components/TooltipOverlay/TooltipOverlay';

class App extends Component {
    constructor(props) {
        super(props);
        if (typeof document === "undefined") {
            props.setRegions(props.store.regions)

            if (props.isB2b) {
                props.setRoutes('b2b', props.store.routes.b2b)
                props.setRoutes('meta', props.store.routes.meta)
            } else {
                // проверка необходима для работы личного кабинета
                if (!props.isLk) {
                    // роуты для динамических страниц ("Работа в Мотив" и "Пресс-центр")
                    props.setRoutes('list', props.store.routes.list)
                    props.setRoutes('supportsRoute', props.store.routes.supportsRoute)
                    // роуты для динамических страниц ("ПОМОЩЬ и еще пяти страниц")
                    props.setRoutes('vacancyRoutes', props.store.routesVacancies.vacancyRoutes)
                    props.setRoutes('vacancyList', props.store.routesVacancies.list)
                }
            }
        }

        let headerClassName = '';
        if (props.isB2b) {
            headerClassName = typeof document === "undefined" ? props.store.routes.meta.headerClassName : props.routes.meta.headerClassName
        } else if (!props.isLk) {
            headerClassName = getHeaderInfo(props.location.pathname)
        }

        this.state = {
            selectedRegion: typeof document === "undefined" ? props.store.regions.selected : props.regions.selected,
            headerClassName,
            meta: props.isB2b ? typeof document === "undefined" ? props.store.routes.meta : props.routes.meta : '',
            loadingPage: {},
            notFound: props.regions.notFound,
            cookieAgreement: 1,
            // cookieAgreement: typeof document === "undefined" || typeof getCookie('expirationCookieAgreement') !== 'undefined' ? 1 : 0,
            megaMenuOpened: false,
            becomeClientOpened: false,
            appRoutes: this.prepareRoutes(),
            checkLoginError: false,
            localAccessToken: '',
            localRefreshToken: '',
        }
    }

    static childContextTypes = {
        selectedRegion: PropTypes.object,
        megaMenuOpened: PropTypes.object,
        fetchBefore: PropTypes.func,
        regions: PropTypes.array,
        toggleRegion: PropTypes.func,
        pushHistory: PropTypes.func,
        locationPath: PropTypes.string,
        scrollTo: PropTypes.func,
        setHeaderClassName: PropTypes.func,
        setBodyClassName: PropTypes.func,
        fetchBeforeAfterSubmit: PropTypes.func,
        toggleOpenedClass: PropTypes.func,
        b2bModalToggle: PropTypes.func,
        goBack: PropTypes.func,
        isLk: PropTypes.bool,
        isB2b: PropTypes.bool,
        appRoutes: PropTypes.array
    };

    getChildContext() {
        return {
            selectedRegion: this.state.selectedRegion,
            megaMenuOpened: this.state.megaMenuOpened,
            fetchBefore: this.fetchBefore,
            regions: typeof document === 'undefined' ? this.props.store.regions.list : this.props.regions.list,
            toggleRegion: this.toggleRegion,
            pushHistory: this.pushHistory,
            locationPath: this.props.location.pathname,
            scrollTo: this.scrollTo,
            setHeaderClassName: this.setHeaderClassName,
            fetchBeforeAfterSubmit: this.fetchBeforeAfterSubmit,
            toggleOpenedClass: this.toggleBodeOpenedClass,
            goBack: this.goBack,
            isLk: this.props.isLk,
            isB2b: this.props.isB2b,
            b2bModalToggle: this.b2bModalToggle,
            appRoutes: this.state.appRoutes
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isB2b && prevState.meta.title !== nextProps.routes.meta.title &&
            prevState.headerClassName !== nextProps.routes.meta.headerClassName) {
            return {
                ...prevState,
                headerClassName: nextProps.routes.meta.headerClassName,
                meta: nextProps.routes.meta
            };
        }
        return prevState;
    }

    goBack = () => {
        this.props.store.dispatch(
            goBack()
        );
    };

    openMenu = () => {
        this.setState((prevState) => {
            this.toggleBodeOpenedClass();
            // if(prevState.megaMenuOpened) {
            if(!prevState.megaMenuOpened) {
                this.props.stateSearchInputAction('');
                this.props.clearSearchHintAction();
                this.props.toggleSearchInputAction(false);
            }
            return {
                megaMenuOpened: !prevState.megaMenuOpened
            }
        });
    };

    toggleBodeOpenedClass() {
        document.body.classList.toggle('mobile-vacancy');
    }

    setHeaderClassName = (className, inState = false) => {
        if (typeof document !== 'undefined' && document.querySelector('.page__header-holder')) {
            if (inState) {
                this.setState({ headerClassName: className })
            }
            // let header = document.getElementsByTagName("header")[0];
            const classesArr = className.split(' ');
            if(classesArr.indexOf('holder_orange') === -1) {
                document.querySelector('.page__header-holder').classList.remove('holder_orange');
            }
            for (let i = 0; i < classesArr.length; i++) {
                document.querySelector('.page__header-holder').classList.add(classesArr[i]);
            }
            // header.className = className;
        }
    };

    pushHistory = (url, args) => {
        if (this.state.megaMenuOpened) {
            this.setState({ megaMenuOpened: false });
            this.toggleBodeOpenedClass();
        }
        this.props.store.dispatch(
            push(url, args)
        );
    };

    toggleRegion = (item) => {
        let path = getPathWithoutRegion(this.props.location.pathname);
        location.href = '/' + item.alias + '/' + path;
    };

    setUtmCookie = () => {
        if (typeof document !== "undefined" && typeof window !== 'undefined') {
            const utm_start = getCookie("utm_start");
            const copy_start_params = getCookie("copy_start_params");
            const utm_from_url = checkUtmParams();

            if (!this.props.isGuest) {
                if (typeof utm_start === 'undefined' && typeof copy_start_params === 'undefined') {
                    setUtmCookie(1, "utm_start", utm_from_url);
                    setUtmCookie(2, "copy_start_params", utm_from_url);
                }
            }
        }
    }

    componentWillMount() {
        const {
            isB2b,
            isLk,
            isGuest,

            footer,
            getFooterMenuList,

            megaMenu,
            getMegaMenu,

            mainMenu,
            getMainMenu,

            settings,
            getSettings,

            chatFormItems,
            getChatForm,

            routes,
            getRoutes,
            routesVacancies,
            getVacancyRoutes,
            settingsConstructor,
            getSettingsConstructor,

            clientInfo,
            getInfo,
            client,
            getLkFormsSettings,
            getLkMenu,
            getLkSettings,
        } = this.props;

        const selectedRegionId = this.state.selectedRegion.id;

        if (footer.footerMenuList.length === 0) {
            getFooterMenuList();
        }
        if (mainMenu.mainMenuList.length === 0) {
            getMainMenu(selectedRegionId, isB2b);
        }
        if (Object.keys(settings).length === 0) {
            getSettings();
        }

        if (chatFormItems && chatFormItems.length === 0) {
            getChatForm();
        }

        if (!isB2b) {
            if (megaMenu.megaMenuList.length === 0) {
                getMegaMenu(selectedRegionId);
            }
            if (routes.supportsRoute.length === 0) {
                getRoutes();
            }
            if (routesVacancies.vacancyRoutes.length === 0) {
                getVacancyRoutes();
            }
            if (!client.formsSettings || Object.keys(client.formsSettings).length === 0) {
                getLkFormsSettings();
            }
            if (!settingsConstructor) {
                getSettingsConstructor(selectedRegionId)
            }

            this.setUtmCookie();
        }

        if (!isB2b) {
            this.setState({ headerClassName: this.state.notFound ? '' : getHeaderInfo(this.props.location.pathname) })
        }

        if (typeof document !== "undefined") {
            if (typeof getCookie('expirationCookieAgreement') !== 'undefined') {
                this.setCookieAgreement();
            }
        }

        if (isB2b) {
            this.props.flagIsB2bAction()
        }
    }

    componentDidMount() {
        const {
            isB2b,

            clientInfo,
            getInfo,
            client,
            getLkMenu,
            getLkSettings,
            checkLogin,
            callLogin,
            logout,

            getMegaMenu
        } = this.props;

        if(hasQueryParam('code')) {
            checkLogin(getQueryParam('code'));
        }
        if(hasQueryParam('access_token')) {
            checkLogin(getQueryParam('access_token'), true);
        }
        // #1
        const selectedRegionId = this.state.selectedRegion.id;
        getMegaMenu(selectedRegionId);

        if (!isB2b && getCookie("access_token")) {
            if (Object.keys(clientInfo).length === 0) {
                getInfo();
            }
            if (!client.lkMenu || client.lkMenu.length === 0) {
                getLkMenu();
            }
            if (!client.settings || Object.keys(client.settings).length === 0) {
                getLkSettings();
            }
        }

        // // если в url после основного пути http://localhost:3003/kurgan_oblast/
        // const hashURL = window.location.hash;
        // // сравниваем на наличие строки в URL
        // const desiredURL = hashURL.includes('#stat-klientom')

        // if (desiredURL) {
        //     // this.b2bModalToggle()
        //     this.setState({becomeClientOpened: true})
        // }
        if (typeof document !== "undefined") {
            if (typeof getCookie('expirationCookieAgreement') === 'undefined') {
                this.setState({
                    cookieAgreement: 0
                });
            }
        }
    }

    fetchBefore = (location, id = null, args = {}) => {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();

            this.checkNFetch(location, id, null, args);
        }
    };

    fetchBeforeAfterSubmit = (location, id = null, args = {}) => {
        this.checkNFetch(location, id, null, args);
    };

    checkNFetch(location, id = null, changeRegionId = null, args) {
        let region = getRegionFromLocation(location);
        let path = getPathWithoutRegion(location);
        let { data, lkData } = getPreloadInfo(location, this.state.appRoutes);

        let requestObject = data ? data : [];
        if (!this.props.isGuest) {
            requestObject = [...requestObject, ...lkData];
        }
        let regionId = this.state.selectedRegion.id;

        if (changeRegionId) {
            regionId = changeRegionId;
        }

        this.state.loadingPage = {
            url: '/' + region + '/' + path,
            requestObject: requestObject,
            args: args
        };

        if (this.checkObj(requestObject, id, changeRegionId)) {
            this.pushHistory('/' + region + '/' + path, args);
        } else {
            for (let i = 0; i < requestObject.length; i++) {
                if (requestObject[i].checkField) {
                    this.props[requestObject[i].func].apply(this, [id, regionId, ...requestObject[i].args]);
                } else {
                    let args = [];

                    requestObject[i].args.forEach(function (item) {
                        args.push(item);
                    });

                    if (requestObject[i].withRegion) {
                        args.unshift(regionId);
                    }

                    this.props[requestObject[i].func].apply(this, args);
                }
            }
        }
    }

    checkObj = (requestObject, id = null, changeRegionId = null) => {

        for (let i = 0; i < requestObject.length; i++) {

            let pathArr = requestObject[i].object.split('.');

            if (this.props[pathArr[0]] && !this.props[pathArr[0]].hasOwnProperty([pathArr[1]])) {
                return false;
            }

            if (typeof this.props[pathArr[0]] === 'undefined' || typeof this.props[pathArr[0]][pathArr[1]] === 'undefined' || this.props[pathArr[0]][pathArr[1]].length === 0) {
                return false;
            }

            if (requestObject[i].checkField) {
                if (typeof this.props[pathArr[0]][pathArr[1]].find(x => x[requestObject[i].checkField] === id) === 'undefined') {
                    return false;
                }
            }

            let regionId = this.state.selectedRegion.id;

            if (changeRegionId) {
                regionId = changeRegionId;
            }

            if (requestObject[i].withRegion) {

                if (this.props[pathArr[0]][pathArr[1]].hasOwnProperty('region_id')) {
                    return this.props[pathArr[0]][pathArr[1]]['region_id'] === regionId;
                } else {

                    if (this.props[pathArr[0]][pathArr[1]].hasOwnProperty('regions')) {

                        if (typeof this.props[pathArr[0]][pathArr[1]]['regions'].findIndex(x => x.region_id === regionId) === 'undefined') {
                            return false;
                        }

                    } else {
                        if (requestObject[i].hasOwnProperty('checkRegionArr')) {
                            for (let key in requestObject[i].checkRegionArr) {
                                if (typeof this.props[pathArr[0]][pathArr[1]][key].findIndex(x => x.region_id === regionId) === 'undefined') {
                                    return false;
                                }
                            }
                        } else {

                            if (Array.isArray(this.props[pathArr[0]][pathArr[1]]) && typeof this.props[pathArr[0]][pathArr[1]].findIndex(x => x.region_id === regionId) === 'undefined') {
                                return false;
                            }
                        }
                    }
                }
            }

            if (changeRegionId) {
                if (requestObject[i].withRegion) {
                    return false;
                }
            }

            if (requestObject[i].hasOwnProperty('alias')) {
                for (let j = 0; j < requestObject[i].alias.length; j++) {
                    if (this.props[pathArr[0]][pathArr[1]].findIndex(x => x.type.alias === requestObject[i].alias[j].type) < 0) {
                        return false;
                    }
                }
            }
        }

        return true;
    };

    checkObj2 = (loadingPage, prevProps) => {

        let result = true;

        for (let i = 0; i < loadingPage.requestObject.length; i++) {
            let pathArr = loadingPage.requestObject[i].object.split('.');

            if (this.props[pathArr[0]] && prevProps[pathArr[0]] && this.props[pathArr[0]][pathArr[1]] !== prevProps[pathArr[0]][pathArr[1]]) {
                loadingPage.requestObject[i].objectReceived = true;
            }

            if (!loadingPage.requestObject[i].objectReceived) {
                result = false;
            }
        }

        if (!result) {
            return result;
        } else {
            loadingPage.requestObject.length ? loadingPage.requestObject.forEach(function (elem) {
                elem.objectReceived = false;
            }) : false;
        }

        return result;
    };

    componentDidUpdate(prevProps, prevState) {

        const {
            loadingPage,
            notFound
        } = this.state;

        const {
            isGuest,
            isB2b,
            isLk,
            client,
            getLkMenu,
            getLkSettings,
            getLkFormsSettings,
            routes,
            getAllMyAppeals,
        } = this.props;
        if(client.checkLoginError !== prevProps.client.checkLoginError && client.checkLoginError) {
            this.setState({checkLoginError: true});
            if (typeof document !== 'undefined') {
                document.body.classList.add("modal-active");
            }
        }
        if (JSON.stringify(loadingPage) !== '{}') {

            let location = loadingPage.url;
            let region = getRegionFromLocation(location);
            let path = getPathWithoutRegion(location);
            let args = loadingPage.args;
            if (this.checkObj2(loadingPage, prevProps)) {
                this.setState({ loadingPage: {}, notFound: false }, () => {
                    this.pushHistory('/' + region + '/' + path, args);
                });
            }
        }

        if (prevProps.isGuest && !isGuest) {
            if (!client.lkMenu || client.lkMenu.length === 0) {
                getLkMenu();
            }

            if (!client.settings || Object.keys(client.settings).length === 0) {
                getLkSettings();
            }

            if (!client.formSettings || Object.keys(client.formSettings).length === 0) {
                getLkFormsSettings();
            }
        }

        if (!prevProps.clientInfo.username && client.clientInfo.username) {
            getAllMyAppeals(client.clientInfo.username);
        }

        if (isLk) {
            if (((!prevProps.client.lkRoutes || !prevProps.client.lkRoutes.length) && client.lkRoutes && client.lkRoutes.length) || (!prevProps.isGuest && isGuest)) {
                this.setState({ appRoutes: this.prepareRoutes() })
            }
        }

        this.setUtmCookie()

        if (!isB2b && (prevState.notFound !== notFound || prevProps.location.pathname !== this.props.location.pathname)) {
            this.setState({ headerClassName: this.state.notFound ? '' : getHeaderInfo(this.props.location.pathname) })
        }

        setTimeout(() => {
            // если в url после основного пути http://localhost:3003/kurgan_oblast/
            const hashURL = window.location.hash;
            // сравниваем на наличие строки в URL
            const desiredURL = hashURL.includes('#stat-klientom')

            if (desiredURL) {
                // this.b2bModalToggle()
                this.setState({ becomeClientOpened: true })
            }
        },);
    }

    scrollTo = (id) => {
        let elem = document.getElementById(id);
        if (elem) {
            let to = elem.getBoundingClientRect().top;
            window.scrollTo(0, to);
        }
    };

    acceptAgreement = () => {
        this.setCookieAgreement();
        this.setState({
            cookieAgreement: 1
        });
    };

    setCookieAgreement = () => {
        setCookie('expirationCookieAgreement', '1', {
            path: '/',
            expires: this.props.settings.cookieDays * 3600 * 24,
            domain: cookieDomain
        });
    };

    prepareRoutes = () => {

        const props = typeof document === 'undefined' ? this.props.store : this.props;

        const {
            isGuest,

            routes,
            routesVacancies,
            settingsConstructor,
        } = props;

        const {
            client,
            isLk,
            isB2b
        } = this.props;

        if (isB2b) {
            return [...caseDynamicRoutesB2b(routes.b2b), notFound];
        }

        if (isLk) {
            return isGuest ? [
                {
                    path: "*",
                    component: LKAuth,
                    requests: [],
                    headerClass: ""
                }
            ] : [
                ...lkRoutes,
                ...!client.lkRoutes || !client.lkRoutes.length ? [
                    {
                        path: "*",
                        component: () => <main className="page__main-holder" role="main" />,
                        exact: true,
                        requests: [],
                        headerClass: ""
                    },
                ] : [...client.lkRoutes.map(
                    (item) => {
                        return {
                            ...item,
                            component: LKPage
                        }
                    }), notFound]
            ]
        }

        return [
            ...initRoutes,
            ...routesVacancies.vacancyRoutes,
            ...routes.supportsRoute,
            ...settingsConstructor ? [{
                path: "/:region/" + settingsConstructor.route,
                component: TariffConstructorAbout,
                exact: true,
                requests: [],
                headerClass: ""
            },
                notFound] : []
        ];
    }

    b2bModalToggle = (e) => {
        if (e) {
            e.preventDefault()
        }
        this.setState((prevState) => ({ becomeClientOpened: !prevState.becomeClientOpened }))

        // находим основной путь http://localhost:3003/kurgan_oblast/
        let basicUrl = window.location.origin + window.location.pathname;
        // находим данные в нужном поле search
        let searchURL = window.location.search
        // достаем весь #hash
        let hashURL = window.location.hash
        // разделяем на 2 части до нужного знака (?)
        const splits = hashURL.split('?');

        if (!this.state.becomeClientOpened) {
            if (splits.length > 1) {
                history.pushState(null, null, basicUrl + hashURL);
            } else {
                // добавляем нужный #hash при открытии модалки
                window.location.hash = 'stat-klientom'
                let hashURL = window.location.hash
                // достаем весь #hash
                history.replaceState(null, null, basicUrl + hashURL + searchURL);
            }
        } else {
            if (splits.length > 1) {
                history.pushState(null, null, basicUrl + '?' + splits[1]);
            } else {
                history.pushState(null, null, basicUrl);
            }
        }
    }

    render() {

        let {
            chatFormItems,
            client,
            settings,
            megaMenu,
            mainMenu,
            isB2b,
            isLk,
            footer,
            cookies,
            routes,
        } = this.props;

        let { headerClassName, megaMenuOpened, notFound, cookieAgreement, appRoutes } = this.state;

        const meta = routes.meta || {};

        return (
            <div className="page__main-content-wrap">
                {/* тултип связанный с поиском */}
                {/* <TooltipOverlay cookieAgreement={cookieAgreement}/> */}

                <Helmet>
                    <title>{meta.title ? meta.title : 'Мотив'}</title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    {meta.description ? <meta name="description" content={meta.description} /> : null}
                    {meta.keywords ? <meta name="keywords" content={meta.keywords} /> : null}
                    {settings.yaVerification !== 'empty'
                        ? <meta name="yandex-verification" content={settings.yaVerification} />
                        : ''
                    }
                    <link
                        href="https://fonts.googleapis.com/css?family=PT+Sans:400,700|Ubuntu:400,500,700&amp;subset=cyrillic"
                        rel="stylesheet" />
                    <link rel="stylesheet" href="/stylesheets/main.css" />
                    <link rel="stylesheet" type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                </Helmet>
                <MobileAppAdvert text={this.props.settings.qr_code_description} />
                <Header
                    openMenu={this.openMenu}
                    megaMenuOpened={megaMenuOpened}
                    headerClassName={isLk ? '' : ' ' + headerClassName}
                    megaMenu={megaMenu.megaMenuList}
                    mainMenu={mainMenu.mainMenuList}
                    parentProps={this.props}
                    isB2b={isB2b}
                />
                {notFound
                    ? <Route component={Page404} />
                    : <Switch>
                        {appRoutes.map(route => {
                            if (route.queryDependent) {
                                const RouteComponent = route.component;
                                let newroute = { ...route };
                                delete newroute['component'];

                                return <Route key={route.path} {...newroute}
                                    render={() => <RouteComponent key={this.props.location.key} />}
                                    cookies={cookies} />
                            }
                            return <Route key={route.path}  {...route} cookies={cookies} />;
                        })}
                    </Switch>
                }
                {chatFormItems && chatFormItems.enable === true ?
                    <AvayaChat items={chatFormItems && chatFormItems.params} client={client} isB2b={isB2b} /> : null}

                {!cookieAgreement &&
                    (<CookieAgreement accept={this.acceptAgreement} />)
                }
                {/* <CookieAgreement accept={this.acceptAgreement} cookieAgreement={cookieAgreement}/> */}
                <Footer footerMenuList={footer.footerMenuList} />
                {isB2b && this.state.becomeClientOpened ? <Modal close={this.b2bModalToggle}>
                    <B2bTariffConnectionModal />
                </Modal> : null}
                {
                    this.state.checkLoginError &&
                    <Modal
                        close={() => {document.body.classList.remove("modal-active"); this.setState({checkLoginError: false});}}
                        className="u-modal u-modal_active modal-fullscreen modal-email modal-email-success modal-email-subscribed"
                    >
                        <div className="content">
                            <SuccessForm
                              title={client.loginErrorText || "К сожалению, возникла ошибка при получении операции. Повторите ошибку позже."}
                              icon={false}
                              linkButton={false}
                              fixedBtnOk={true}
                              close={() => {document.body.classList.remove("modal-active"); this.setState({checkLoginError: false});}}
                            />
                        </div>
                    </Modal>
                }

                <AdvertisingLabelingMob />
                {
                    process.env.NODE_ENV === 'development' &&
                    <LocalTokenBlock />
                }
            </div>
        )
    }
}


export default withRouter(connect(
    state => ({
        regions: state.regions,
        tariffs: state.tariffs,
        services: state.services,
        internet: state.internet,
        faq: state.faq,
        infoBlocks: state.infoBlocks,
        completeSets: state.completeSets,
        faqHomeInternet: state.faq,
        filterSettings: state.tariffs.filterSettings,
        selectionSettings: state.tariffs.selectionSettings,
        footer: state.footer,
        megaMenu: state.megaMenu,
        shops: state.shops,
        forms: state.forms,
        settings: state.settings,
        activation: state.activation,
        support: state.support,
        mainMenu: state.mainMenu,
        isGuest: state.client.isGuest,
        clientInfo: state.client.clientInfo,
        client: state.client,
        loadingBar: state.loadingBar,
        contactsList: state.contacts,
        vacanciesList: state.vacancies,
        newsList: state.news,
        // роуты для динамических страниц ("Работа в Мотив" и "Пресс-центр")
        vacancyRoutes: state.routesVacancies.vacancyRoutes || [],
        routesVacancies: state.routesVacancies || {},
        // роуты для динамических страниц ("ПОМОЩЬ и еще пяти страниц")
        supportsRoute: state.routes.supportsRoute || [],
        routes: state.routes || {},
        chatFormItems: state.chatFormFunc.chatFormItems,
        // axis: state.constructor.axis,
        settingsConstructor: state.constructor.settings,
        tariffConstructor: state.tariffConstructor,
        company: state.company
    }),
    (dispatch) => {
        return {
            getTariffsArchive: (regionId) => dispatch(tariffArchiveList(regionId)),
            getTariffsIndividual: (regionId) => dispatch(tariffIndividualList(regionId)),
            getTariffs: (regionId, categoryRankField, rankField, businessLine) => dispatch(tariffsList(regionId, categoryRankField, rankField, businessLine)),
            getService: (slug, regionId, businessLine) => dispatch(servicesView(slug, regionId, businessLine)),
            getServices: (regionId, categoryRankField, serviceRankField, businessLine) => dispatch(servicesList(regionId, categoryRankField, serviceRankField, businessLine)),
            getMyServices: () => dispatch(services()),
            getServicesArchive: (regionId) => dispatch(servicesArchiveList(regionId)),
            getInfoBlocks: (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getInternetItemsList: (regionId) => dispatch(internetItemsList(regionId)),
            getFaq: (categoryId) => dispatch(faqList(categoryId)),
            getCompleteSetsList: (regionId) => dispatch(completeSetsList(regionId)),
            getTariffFilterSettings: () => dispatch(tariffFilterSettings()),
            getTariffSelectionSettings: (regionId) => dispatch(tariffSelectionSettings(regionId)),
            getFooterMenuList: () => dispatch(footerMenuList()),
            getMegaMenu: () => dispatch(megaMenuList()),
            getShopsList: (regionId) => dispatch(shopsList(regionId)),
            getForms: (group) => dispatch(formsList(group)),
            getSettings: () => dispatch(settingsList("gtmId;cookieDays;yaVerification;coverCoords;callback_form_phone_number;callback_form_phone_number_b2b;googleCaptcha;qr_code_description")),
            getRoamingSettings: () => dispatch(settingsList("roamingMainText;roaming_i_go_russia_example_regions;roaming_i_go_example_regions;roaming_i_call_example_regions;roamingFooterTextIGoRussia;roamingFooterTextIGo;roamingFooterTextICall")),
            getRoutes: () => dispatch(routesList()),
            getFastDecisions: (regionId) => dispatch(fastDecisions(regionId)),
            getMainMenu: (regionId, isB2b) => dispatch(mainMenuList(regionId, isB2b)),
            checkLogin: (code, cssToken) => dispatch(checkLogin(code, cssToken)),
            getInfo: () => dispatch(info()),
            getCurrentTariff: () => dispatch(tariffInfo()),
            getDetails: () => dispatch(details()),
            getPayments: () => dispatch(payments()),
            getBanServices: () => dispatch(banServices()),
            getRemainsHistory: () => dispatch(remainsHistory()),
            getExchange: (regionId) => dispatch(exchangeMins(regionId)),
            getLkFormsSettings: () => dispatch(formsSettings()),
            getLkMenu: () => dispatch(lkMenu()),
            getLkSettings: () => dispatch(lkSettings()),
            getContactsList: (name) => dispatch(contactsList(name)),
            getVacanciesList: () => dispatch(vacanciesList()),
            getVacancyRoutes: () => dispatch(routesVacanciesList()),
            getNewsList: (listType) => dispatch(newsList(listType)),
            getComplain: () => dispatch(complain()),
            getServiceRules: () => dispatch(termsOfService()),
            getChatForm: () => dispatch(chatForm()),
            // getAxis: (regionId) => dispatch(axisValues(regionId)),
            getSettingsConstructor: (regionId) => dispatch(settings(regionId)),
            getTariffConstructor: (regionId) => dispatch(getTariffConstructor(regionId)),
            getContactsAbout: () => dispatch(contactsAbout()),
            getDocsAbout: (region, businessLine = 'b2c') => dispatch(docsAbout(region), businessLine),
            getNewsListShort: (listType) => dispatch(newsListShort(listType)),
            getPage: (page, region, company) => dispatch(getPage(page, region, company)),
            getCompanies: (regionId) => dispatch(getCompanies(regionId)),
            getUssd: (regionId) => dispatch(ussd(regionId)),
            setRegions: (data) => dispatch(setRegions(data)),
            setRoutes: (type, data) => dispatch(setRoutes(type, data)),
            flagIsB2bAction: () => dispatch(flagIsB2bAction()),
            clearSearchHintAction: () => dispatch(clearSearchHintAction()),
            stateSearchInputAction: (data) => dispatch(stateSearchInputAction(data)),
            toggleSearchInputAction: (data) => dispatch(toggleSearchInputAction(data)),
            logout: () => dispatch(logout()),
            getAllMyAppeals: (phone) => dispatch(getAllMyAppeals(phone)),
        }
    }
)(App));