import React, { Component } from 'react';
import {mediaUrl} from "../../tools/config";
import Slider from 'react-slick';
import YouTube from 'react-youtube';
import LinkC from "../common/LinkC";

class CarouselAboutCompanyVideo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dots: [],
            hideText: true,
            documentLoad: false, //состояние которое позволяет использоваь window и document в ssr(по умолчанию ssr их не видит)
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slickTo = this.slickTo.bind(this);
    }

    componentWillMount () {

        let dots = [];

        this.props.blocks.forEach(function (el, i) {
            dots.push({
                active: i === 0,
            });
        });

        this.state.dots = dots;
    }

    componentDidMount(){
        this.setState({
            documentLoad: true
        })

        // останавливает проигрывание видео при нажитии на кнопки перключения слайдов
        const changeSlide = document.querySelectorAll('.carousel-about-company-video__arrow');
        changeSlide.forEach((item) => (
            item.addEventListener('click', () => {
                const frame = document.querySelectorAll('.carousel-about-company-video__player iframe');

                // останавливаем все видео при клике на кнопку переключения слайдов
                frame.forEach((item4) => {
                    item4.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                });
            })
        ));
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    slickTo (e) {
        let idx = e.target.getAttribute('data-index');
        this.slider.slickGoTo(idx);
    }

    changeDot (idx) {
        this.setState(() => {
            this.state.dots.forEach(function (el) {
                el.active = false;
            });
            this.state.dots[idx].active = true;
            return {dots : this.state.dots}
        });
    }

    playSlider = () => {
        let { blocks } = this.props;
        let typeSettings = blocks.length ? blocks[0].type : {};
        if ( typeSettings.allow_auto_slick === true ) {
            this.slider.slickPlay();
        }
        this.setState({
            hideText: true
        });
    };

    pauseSlider = () => {
        this.slider.slickPause();
        this.setState({
            hideText: false
        });
    };

    render() {
        let { blocks, linkMediaBank } = this.props;
        let typeSettings = blocks.length ? blocks[0].type : {};

        const settings = {
            dots: false,
            infinite: true,
            autoplay: typeSettings.allow_auto_slick,
            speed: 1000,
            autoplaySpeed: typeSettings.auto_slick_time*1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: false,
            draggable: false,
            beforeChange: (current, next) => this.changeDot(next)
        };

        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
                autoplay: 0,
                controls: 0,
                iv_load_policy: 3,
                loop: 1,
                modestbranding: 1,
                showinfo: 0,
                rel: 0,
                enablejsapi: 1,
                origin: this.state.documentLoad ? window.location.origin : null
            }
        };

        let { hideText } = this.state;

        return (
            <div className="b-services-list__services-slider b-service-slider__video">
                <div className="carousel-about-company-video__top">
                    <span className="carousel-about-company-video__title">Медиа-банк</span>
                    <LinkC to={linkMediaBank + "#videoAll"} className="carousel-about-company-video__link" >Смотреть все</LinkC>
                </div>
                <div className="b-service-slider b-service-slider_adapt_default js-init">
                    <div className="b-service-slider__wrapper">
                        <div className="b-service-slider__items-list">
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {blocks.map((block, index) =>
                                    <div className="carousel-about-company-video" key={index}>
                                        {/https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi.exec(block.videoPath) !== null ?
                                            <YouTube
                                                containerClassName="carousel-about-company-video__player"
                                                videoId={ /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/watch(?:\?|#\!)v=)([\w-]{11}).*/gi.exec(block.videoPath)[1] }
                                                opts={ opts }
                                                onPlay={ this.pauseSlider }
                                                onPause={ this.playSlider }
                                                onEnd={ this.playSlider }
                                            /> :
                                        <div className="carousel-about-company-video--img"
                                             style={{backgroundImage: 'url('+ mediaUrl + block.imagePath + ')',
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center",
                                                 width: "100%",
                                                 height: "100%"
                                             }} /> }
                                        {hideText ? <div className="carousel-about-company-video__txt-block">
                                            <div className={"carousel-about-company-video__title-min " + ( !!block.imagePath ? " carousel-about-company-video__title-min--img" : "")} dangerouslySetInnerHTML={{__html: block.title}}/>
                                            <div className="carousel-about-company-video__subtitle-min" dangerouslySetInnerHTML={{__html: block.text}}/>
                                        </div> : null}
                                    </div>
                                )}
                            </Slider>
                        </div>

                        <div onClick={this.previous} className="carousel-about-company-video__arrow carousel-about-company-video__arrow_left" />
                        <div onClick={this.next} className="carousel-about-company-video__arrow carousel-about-company-video__arrow_right" />
                        
                        <div className="b-service-slider__dots b-service-slider__dots-press-center">
                            <ul className="slick-dots" role="tablist">
                                {this.state.dots.map((dot, index) =>
                                    <li className={dot.active ? "slick-active" : ""} role="presentation" key={index}>
                                        <button type="button" onClick={this.slickTo} data-index={index}/>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                className="b-main-slider__circle"
                                                version="1.1"
                                                viewBox="0 0 60 60">
                                            <circle className="circle__background" cx="30" cy="30" r="25" stroke="hsla(0,0%,100%,.5)" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                            <circle className="b-main-slider__path" style={{animationDuration: (7*typeSettings.auto_slick_time)+"s"}} cx="30" cy="30" r="25" stroke="#fff" strokeWidth="3" fill="rgba(0,0,0,0)"/>
                                        </svg>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CarouselAboutCompanyVideo;